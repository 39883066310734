/*
* @About gongw created by 2021/2/1
*/
import request from '@/lib/utils/request'

// 获取订单列表
export function getOrderList (data) {
  return request({
    url: '/buiness/info/tList',
    method: 'get',
    params: data
  })
}
// 理赔--退保
export function postCkType (data) {
  return request({
    url: '/buiness/gat/ckType',
    method: 'get',
    params: data
  })
}
// 撤销理赔--退保
export function reCkType (data) {
  return request({
    url: '/buiness/gat/reCkType',
    method: 'get',
    params: data
  })
}
