<template>
  <div class="detail-containter">
    <nav-menu></nav-menu>
    <div class="detail-body">
      <div class="containter">
        <div class="detail-title flex-a flex-b">
          <span>订单详情<span>{{ orderNo ? '#' + orderNo : '' }}</span></span>
          <img src="@/assets/back.svg" @click="toOrder" alt="">
        </div>
        <div class="detail-list">
          <div class="detail-list-title flex-a"><span>{{ form.tendName }}</span>
            <el-tag size="small" v-if="form.type == '1' && form.state == '3'"
                    :type="form.status == '0' ? 'primary' : form.status == 'warning' ? '' : form.status == '2' ? 'info' : form.status == '3' ? 'success' : ''">
                    {{ form.status == '0' ? '申请中' : form.status == '1' ? '已申请' : form.status == '2' ? '已取消' :
                        form.status
                          ==
                          '3' ? '已完成' : ''
                    }}</el-tag>
                  <el-tag size="small" v-if="form.status == '1' && form.state != '3'"
                    :type="form.state == '0' ? 'primary' : form.state == 'warning' ? '' : form.state == '2' ? 'info' : form.state == '3' ? 'success' : ''">
                    {{ form.state == '0' ? '待缴费' : form.state == '1' ? '未缴费' : form.state == '2' ? '已超时' :
                        form.state
                          ==
                          '3' ? '已缴费' : ''
                    }}</el-tag>
                  <el-tag size="small" type="danger" v-if="form.type != '1' && form.type != '0'">
                    {{ form.type == '2'&&form.status!='3' ? '退保中':form.type == '2'&&form.status=='3'?'已退保' : form.type == '3'&&form.status!='3' ? '理赔中' :  form.type == '3'&&form.status=='3' ? '已理赔':'' }}</el-tag>
          </div>
          <div class="detail-item">
            <div>标段编号：{{ form.tendNo }}</div>
            <div>保证金金额：￥{{ form.prjEarMoney }}</div>
            <div>开标时间：{{ form.tendStDate }}</div>
            <div>保证金缴纳截止时间：{{ form.prjEndEarDate }}</div>
          </div>
          <div class="detail-item">
            <div>经办人姓名：{{ form.name }}</div>
            <!-- <div>经办人邮箱：{{ form.eMail }}</div> -->
            <div>经办人身份证号：{{ form.idCard }}</div>
            <div>经办人手机号：{{ form.phone }}</div>
          </div>
          <div class="detail-item noBorder">
            <div>保函类型：{{form.dbType}}</div>
            <div>申请时间：{{ form.createdAt }}</div>
            <!-- <div>支付金额：￥{{ form.price }}</div> -->
            <!-- <div>支付时间：{{ form.successTime }}</div> -->
          </div>
          <div class="detail-line flex-a flex-b">
            <span class="line"></span>
            <span>{{form.gatUrl?'保函文件':'尚未开函'}}</span>
            <span class="line"></span>
          </div>
          <div class="detail-file" v-if="form.gatUrl">
            <div class="detail-file-title">保函编号：{{ form.gatNo }}</div>
            <div class="detail-file-content flex">
              <img src="@/assets/pdf.svg" alt="">
              <el-tag type="danger" size="small">加密</el-tag>
              <span class="detail-file-content-title">{{ form.gatNo }}</span>
              <span class="btn-plain preview" @click="preview" v-if="form.type=='1'">在线预览</span>
              <span class="btn-plain" @click="preview" v-if="form.type=='1'">本地下载</span>
              <img src="@/assets/reset.svg" v-if="form.type!='1'" alt="" class="reset">
            </div>
          </div>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import { getOrderList } from '@/lib/api/order'
export default {
  name: 'detail',
  components: {},
  data() {
    return {
      orderNo: '',
      form: {}
    }
  },
  created() {
    console.log(this.$route.query.orderNo)
    this.orderNo = this.$route.query.orderNo
    this.getDetail({ orderNo: this.$route.query.orderNo })//获取订单列表详情
  },
  methods: {
    //获取订单列表详情
    getDetail(params) {
      getOrderList(params).then(res => {
        if (res.code == 200) {
          this.form = res.rows[0]
        }
      })
    },
    //返回我的订单
    toOrder() {
      this.$router.push({ path: '/attestation',query:{tab:'order'} })
    },
    // 预览-下载
    preview(){
      if(this.form.isSeal=='0'){
        window.open(this.form.gatUrl)
      }else{
        this.$message({
          message: '尚未盖章，请等待！',
          type: 'warning'
        });
      }
    }
  }
}
</script>
<style scoped lang="less">
.detail-containter {
  background: #F0F2F5;
  min-height: 100vh;

  .detail-body {
    padding: 20px 0;

    .containter {
      .detail-title {
        color: #1890FF;
        font-weight: bold;
        font-size: 20px;
        padding: 10px 40px 30px 40px;

        img {
          width: 30px;
        }

        img:hover {
          cursor: pointer;
        }
      }

      .detail-list {
        .detail-list-title {
          font-size: 16px;
          color: #272727;
          font-weight: bold;
          padding-left: 60px;

          span {
            margin-right: 10px;
          }
        }

        .detail-item {
          border-bottom: 1px solid #E8E8E8;
          padding: 30px 0 15px 100px;

          div {
            display: inline-block;
            width: 45%;
            color: #5A5A5A;
            font-size: 14px;
            margin-bottom: 15px;
          }
        }

        .detail-item.noBorder {
          border: none;
        }

        .detail-line {
          color: #5A5A5A;

          .line {
            background: #E8E8E8;
            display: inline-block;
            width: 45%;
            height: 1px;
          }
        }

        .detail-file {
          padding: 20px 80px 0 80px;

          .detail-file-title {
            color: #5A5A5A;
            font-size: 14px;
          }

          .detail-file-content {
            box-shadow: 0 0 10px #E8E8E8;
            margin-top: 15px;
            padding: 20px 50px 20px 30px;
            border-radius: 5px;
            position: relative;

            img {
              width: 60px;
            }
            .reset{
              position: absolute;
              width: 120px;
              bottom: -10px;
              right: 40px;
            }
            .el-tag {
              margin: 0 10px;
            }

            .detail-file-content-title {
              color: #2C3E50;
            }

            .btn-plain {
              border-color: #157EDF;
              color: #1890FF;
              position: absolute;
              bottom: 20px;
              right: 50px;

              a {
                color: #1890FF;
              }
            }

            .preview {
              right: 150px;
            }
          }
        }
      }
    }
  }
}
</style>
